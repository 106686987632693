import * as React from "react"
import {Footer} from "../components/landingpage/footer";
import {Header} from "../components/header";
import {MetaTags} from "../components/metaTags";
import {Contact} from "../components/contact";

const title = "Contact et prise de rendez-vous avec WealCome";
const metaDescription = "Vous avez une application à réaliser ? Des questions concernant nos pratiques, " +
    "nos formations ou autres ? N'hésitez pas, contactez-nous.";

export default () => {
    return (
        <div>
            <MetaTags title={title} description={metaDescription}/>
            <Header fixedTop={true} backgroundTransition={false}/>
            <div className="main">
                <Contact/>
            </div>
            <Footer/>
        </div>
    )
};
