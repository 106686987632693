import React, {useState} from 'react';
import {Field, Form, Formik} from 'formik';
import * as Yup from 'yup';

const contactFormSchema = Yup.object().shape({
    email: Yup.string()
        .email('Votre e-mail n\'est pas valide')
        .required('Votre e-mail est requis.'),
    theme: Yup.string().required('Le thème de votre message est requis.'),
    message: Yup.string()
        .min(50, 'Message trop court, 50 caractères au minimum')
        .required('Votre message est requis.'),
});

const onSubmit = (setFormSent, setErrorSending) => (values, {resetForm}) => {
    const xhr = new XMLHttpRequest();
    xhr.open("POST", "https://formspree.io/xwknaewj");
    xhr.setRequestHeader("Accept", "application/json");
    xhr.onreadystatechange = () => {
        if (xhr.readyState !== XMLHttpRequest.DONE) return;
        resetForm();
        if (xhr.status === 200) {
            setFormSent(true);
            setErrorSending(false);
        } else {
            setFormSent(false);
            setErrorSending(true);
        }
    };
    const data = new FormData();
    data.append('email', values.email);
    data.append('theme', values.theme);
    data.append('message', values.message);
    xhr.send(data);
};

export const ContactForm = () => {

    const [formSent, setFormSent] = useState(false);
    const [errorSending, setErrorSending] = useState(false);

    const FormComponent = ({errors, touched}) => <Form>
        <div className="form-group">
            <label htmlFor="email">Votre adresse e-mail</label>
            <Field className="form-control" id="email" name="email"/>
            {errors.email && touched.email ? (
                <div className="error-message">{errors.email}</div>
            ) : null}
        </div>

        <div className="form-group">
            <label htmlFor="theme">Thème de votre message</label>
            <Field as="select" className="form-control" id="theme" name="theme">
                <option>Sélectionner un thème</option>
                <option value="Création de mon projet / application">Création de mon projet /
                    application
                </option>
                <option value="Questions au sujet de nos formations">Questions au sujet de nos
                    formations
                </option>
                <option value="Demande de coaching / accompagnement">Demande de coaching /
                    accompagnement
                </option>
                <option value="Demandes d'informations">Demandes d'informations</option>
                <option>Remarques</option>
            </Field>
        </div>
        {errors.theme && touched.theme ? (
            <div className="error-message">{errors.theme}</div>
        ) : null}

        <div className="form-group">
            <label htmlFor="message">Votre message</label>
            <Field as="textarea" className="form-control" id="message" name="message" rows="5"/>
            {errors.message && touched.message ? (
                <div className="error-message">{errors.message}</div>
            ) : null}
        </div>
        <br/>
        <Field type="text" name="_gotcha" style={{display: 'none'}}/>
        <div className="text-right">
            <button type="submit" className="btn btn-primary">Envoyer</button>
        </div>
    </Form>;

    return <div className="contact-form gray-light-bg">
        <Formik
            initialValues={{
                email: '',
                theme: '',
                message: '',
            }}
            validationSchema={contactFormSchema}
            onSubmit={onSubmit(setFormSent, setErrorSending)}
        >
            {({errors, touched}) => {
                return <>
                    {(!formSent || errorSending) && <FormComponent errors={errors} touched={touched}/>}
                    {(errorSending) && <div className="error-message">
                            Désolé, l'envoi a échoué.<br/>
                            Veuillez réessayer dans quelques minutes, merci.</div>
                    }
                    {(formSent && !errorSending) && <div className="success-message">
                        Votre message vient d'être envoyé.<br/>
                        Nous vous répondrons dans les plus brefs délais, merci et bonne journée à vous.</div>
                    }
                </>
            }}
        </Formik>
    </div>;
};