import * as React from "react";
import "./contact.scss";
import {ContactForm} from "./contactForm";
import "./contactForm"

export const Contact = () => {
    return (
        <section className="trainings-section ptb-100">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-7 col-md-8">
                        <div className="section-heading text-center mb-5">
                            <h1>Contact et prise de rendez-vous</h1>
                            <p className="lead">Vous avez une application à réaliser ? <br/>
                                Des questions concernant nos pratiques, nos formations, nos coaching ou autres ? <br/>
                                N'hésitez pas, contactez-nous à travers ce formulaire.
                            </p>
                        </div>
                        <ContactForm/>
                    </div>
                </div>
            </div>
        </section>
    );
};